import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import history from 'stores/history/store';
import { UPDATE_IS_MOBILE } from 'stores/mutationTypes';
import { namespace, UPDATE_ARTICLE_HISTORY } from 'stores/history/mutationTypes';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMobile: false,
  },
  modules: {
    history,
  },
  mutations: {
    [UPDATE_IS_MOBILE](state, isMobile) {
      state.isMobile = isMobile;
    },
  },
  plugins: [
    createPersistedState({
      paths: [
        'history.articles',
      ],
      filter: ({ type }) => type === `${namespace}/${UPDATE_ARTICLE_HISTORY}`,
    }),
  ],
});
