import Vue from 'vue';

import objectFitImages from 'object-fit-images';
import Sticky from 'sticky-js';

import 'functions/pv';
import ArticleHistories from 'components/ArticleHistories.vue';
import addHistory from 'functions/addHistory';
import registerServiceWorker from 'functions/registerServiceWorker';
import truncate from 'functions/truncate';
import welcomeToNewsConsole from 'functions/welcomeToNewsConsole';
import { bindScrollToTop } from 'functions/bindScroll';
import TagExpand from 'modules/TagExpand';
import { UPDATE_IS_MOBILE } from 'stores/mutationTypes';
import store from 'stores/root';
import prefetch from 'serviceworker/prefetch';

registerServiceWorker();

const main = () => {
  // eslint-disable-next-line no-new
  new Vue({
    el: '#js-article-histories',
    store,
    components: {
      ArticleHistories,
    },
    render: h => h(ArticleHistories),
  });

  // eslint-disable-next-line no-new
  new Sticky('#js-sticky-contents', {
    stickyContainer: '#js-sticky-container',
  });
  new TagExpand('.c-tags', '.js-more'); // eslint-disable-line no-new

  store.commit(UPDATE_IS_MOBILE, false);

  objectFitImages();
  truncate('.js-truncate');
  addHistory();
  bindScrollToTop('.js-pagetop');
  welcomeToNewsConsole();
  prefetch();
};

document.addEventListener('DOMContentLoaded', main);
