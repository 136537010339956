const environments = global.getEnvironmentsFromView();

const {
  environment,
  is_preview: isPreview,
  log_endpoint: logEndpoint,
} = environments;

const IS_PRODUCTION = environment === 'production';

if (!isPreview) {
  const xhr = new XMLHttpRequest();
  xhr.open('POST', logEndpoint);
  xhr.withCredentials = !IS_PRODUCTION;
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  xhr.send('event_type=pv');
}
