import axios from 'axios';

const prefetchSelector = '.js-prefetch';

export default () => {
  if (!('serviceWorker' in navigator)) {
    return;
  }

  const elements = document.querySelectorAll(prefetchSelector);
  Array.from(elements).forEach(node => {
    const href = node.getAttribute('href');
    if (!href) {
      return;
    }
    axios.get(href);
  });
};
