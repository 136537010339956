import store from 'stores/root';
import { namespace, UPDATE_ARTICLE_HISTORY } from 'stores/history/mutationTypes';
import { isArticlePage } from 'functions/url';

export default () => {
  if (!isArticlePage(window.location.href, window.location.host)) {
    return;
  }

  const jsonLdElement = document.querySelector("script[type='application/ld+json']");
  if (!jsonLdElement) {
    console.error('JSON-LD data not found.');
    return;
  }

  const article = JSON.parse(jsonLdElement.innerText, (key, value) =>
    (['@context', '@type', 'publisher'].includes(key)
      ? undefined
      : value));

  store.commit(`${namespace}/${UPDATE_ARTICLE_HISTORY}`, article);
};
