export const isArticlePage = (url, host) => {
  const parsedUrl = new URL(url);
  return parsedUrl.host === host
      && /^\/[0-9]+\/$/.test(parsedUrl.pathname);
};

export const isAsset = url => {
  const parsedUrl = new URL(url);
  return /\.(jpg|gif|png|svg|js)$/.test(parsedUrl.pathname);
};

