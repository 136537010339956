import {
  UPDATE_ARTICLE_HISTORY,
  UPDATE_IS_EXPANDED,
} from 'stores/history/mutationTypes';

export default {
  namespaced: true,
  state: {
    articles: [],
    maxArticleLength: 30,
    ui: {
      isShowingOfflinePage: false,
      isExpanded: false,
    },
  },
  mutations: {
    [UPDATE_ARTICLE_HISTORY](state, article) {
      const others = state.articles.filter(item =>
        item.mainEntityOfPage['@id'] !== article.mainEntityOfPage['@id']);
      state.articles = [article, ...others];

      if (state.articles.length > state.maxArticleLength) {
        state.articles = state.articles.slice(0, state.maxArticleLength);
      }
    },
    [UPDATE_IS_EXPANDED](state, isExpanded) {
      state.ui.isExpanded = isExpanded;
    },
  },
};
