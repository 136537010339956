<template>
  <li>
    <a
      :href="article.mainEntityOfPage['@id']"
      class="c-small-thumbnail">
      <div class="c-small-thumbnail__image">
        <img :src="article.image.url" >
      </div>
      <div class="c-small-thumbnail__text">
        <template v-if="isMobile">
          <p class="c-small-thumbnail__title">
            {{ article.headline }}
          </p>
          <div class="c-small-thumbnail__meta">
            <span class="u-font-small">{{ article.author.name }}</span>
            <span class="u-font-small">{{ article.datePublished | formatDate }}</span>
          </div>
        </template>

        <template v-else>
          <div class="c-small-thumbnail__title-wrapper">
            <p
              class="c-small-thumbnail__title js-truncate"
              data-line-limit="3">
              {{ article.headline }}
            </p>
          </div>
        </template>
      </div>
    </a>
  </li>
</template>

<script>
import VueTypes from 'vue-types';
import formatDate from 'functions/formatDate';

export default {
  filters: {
    formatDate,
  },
  props: {
    article: VueTypes.shape({
      mainEntityOfPage: VueTypes.shape({
        '@id': VueTypes.string.isRequired,
      }).isRequired,
      headline: VueTypes.string.isRequired,
    }).loose,
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
};
</script>
