<template>
  <section
    class="p-section"
    v-if="articles.length > 0">
    <h2 class="p-section__heading">あなたが閲覧した記事</h2>
    <div class="p-section__inner">
      <ul :class="classObject">
        <small-thumbnail
          v-for="article in articles"
          :key="article.mainEntityOfPage['@id']"
          :article="article"
        />
        <li>
          <button
            class="p-section__read-more-btn"
            v-show="shouldShowReadMoreButton"
            @click="readMore"
          >もっと見る</button>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import VueTypes from 'vue-types';
import SmallThumbnail from 'components/SmallThumbnail.vue';
import { namespace, UPDATE_IS_EXPANDED } from 'stores/history/mutationTypes';
import { isArticlePage } from 'functions/url';

export default {
  name: 'ArticleHistories',
  components: {
    SmallThumbnail,
  },
  props: {
    initLength: VueTypes.number.def(5),
    maxLength: VueTypes.number.def(10),
  },
  computed: {
    articles() {
      // start from second item to remove current article at article page
      return this.shouldShowReadMoreButton
        ? this.$store.state.history.articles.slice(this.startIndex, this.initLength + this.startIndex)
        : this.$store.state.history.articles.slice(this.startIndex, this.maxLength + this.startIndex);
    },
    startIndex() {
      return Number(isArticlePage(window.location.href, window.location.host));
    },
    shouldShowReadMoreButton() {
      return this.$store.state.history.articles.length > this.initLength + this.startIndex
        && !this.$store.state.history.ui.isExpanded;
    },
    classObject() {
      return {
        'c-list': this.$store.state.isMobile,
        'p-sidebar__item c-pickup-list': !this.$store.state.isMobile,
      };
    },
  },
  methods: {
    readMore() {
      this.$store.commit(`${namespace}/${UPDATE_IS_EXPANDED}`, true);
    },
  },
};
</script>
