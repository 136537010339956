export default class {
  constructor(tagSelector, buttonSelector) {
    this.tagContainer = document.querySelector(tagSelector);
    this.moreButton = document.querySelector(buttonSelector);

    if (!this.tagContainer) {
      return;
    }

    if (!this.shouldShowMoreButton()) {
      this.moreButton.style.display = 'none';
      return;
    }

    this.moreButton.addEventListener('click', this.expandTags.bind(this));
  }

  shouldShowMoreButton() {
    const tags = this.tagContainer.querySelector('ul');
    const style = window.getComputedStyle(tags);

    const wrapHeight = this.tagContainer.clientHeight;
    const objHeight = tags.clientHeight + parseInt(style.marginTop, 10);

    return wrapHeight < objHeight;
  }

  expandTags(event) {
    event.preventDefault();
    this.tagContainer.style.height = 'auto';
    this.moreButton.style.display = 'none';
  }
}
