import jump from 'jump.js';

const baseOption = {
  duration: 300,
};

export const bindScrollToTop = bindSelector => {
  const bindNodeList = document.querySelectorAll(bindSelector);

  Array.from(bindNodeList).forEach(node =>
    node.addEventListener('click', event => {
      event.preventDefault();
      jump('body', baseOption);
    }));
};

export const bindScrollToElement = bindSelector => {
  const bindNodeList = document.querySelectorAll(bindSelector);

  Array.from(bindNodeList).forEach(node =>
    node.addEventListener('click', event => {
      event.preventDefault();

      const scrollSelector = node.getAttribute('href');
      const scrollElement = document.querySelector(scrollSelector);

      if (!scrollElement) {
        throw new Error(`scrollElement is not found. ${scrollSelector}`);
      }

      jump(scrollElement, baseOption);
    }));
};
